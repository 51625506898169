// src/components/Work.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const Work = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <section id="work" className="py-32 relative overflow-hidden">
            <div className="absolute inset-0 pointer-events-none">
                <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_rgba(56,182,255,0.03)_0%,_transparent_60%)]" />
            </div>

            <div className="max-w-7xl mx-auto px-4 relative z-10">
                <motion.div
                    ref={ref}
                    className="text-center"
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8 }}
                >
                    <h2 className="text-5xl md:text-6xl font-bold text-white mb-6">
                        Featured Work
                    </h2>
                    <p className="text-white/60 text-lg max-w-2xl mx-auto mb-16">
                        Discover our latest projects where innovation meets execution
                    </p>

                    <Link to="/portfolio">
                        <motion.div
                            className="relative inline-block group"
                            whileHover={{ scale: 1.02 }}
                            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                        >
                            {/* 3D Shadow Effect */}
                            <div className="absolute -inset-1 bg-gradient-to-r from-[#00ff9580] via-[#00eeff80] to-[#ff69b480] rounded-xl blur-lg opacity-50 group-hover:opacity-75 transition duration-300"></div>

                            {/* Button Base with 3D Effect */}
                            <div className="relative px-12 py-4 bg-black rounded-xl transform transition-transform duration-200 hover:-translate-y-0.5" style={{
                                boxShadow: `
                                    0 4px 6px -1px rgba(0, 0, 0, 0.5),
                                    0 2px 4px -1px rgba(0, 0, 0, 0.3),
                                    inset 0 1px 2px rgba(255, 255, 255, 0.1)
                                `
                            }}>
                                {/* Animated Neon Border */}
                                <motion.div
                                    className="absolute inset-0 rounded-xl overflow-hidden"
                                    style={{
                                        background: `
                                            linear-gradient(90deg, 
                                            #00ff95, #00eeff, #ff69b4, #00ff95)
                                        `,
                                        backgroundSize: '300% 100%',
                                        maskImage: 'radial-gradient(2px at 50% 50%, black 40%, transparent 40%)',
                                        WebkitMaskImage: 'radial-gradient(2px at 50% 50%, black 40%, transparent 40%)',
                                        opacity: 0.8
                                    }}
                                    animate={{
                                        backgroundPosition: ['0% 0%', '150% 0%']
                                    }}
                                    transition={{
                                        duration: 3,
                                        ease: 'linear',
                                        repeat: Infinity
                                    }}
                                />

                                {/* Text with LED effect */}
                                <div className="relative">
                                    {/* Text glow layer */}
                                    <span className="absolute inset-0 text-lg font-medium text-white blur-[1px] opacity-50">
                                        Open Portfolio
                                    </span>
                                    {/* Main text */}
                                    <span className="relative text-lg font-medium text-white" style={{
                                        textShadow: `
                                            0 0 10px rgba(255, 255, 255, 0.5),
                                            0 0 20px rgba(255, 255, 255, 0.3)
                                        `
                                    }}>
                                        Open Portfolio
                                    </span>
                                </div>

                                {/* Inner glow effect */}
                                <div className="absolute inset-0 rounded-xl bg-gradient-to-b from-white/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                            </div>
                        </motion.div>
                    </Link>
                </motion.div>
            </div>
        </section>
    );
};

export default Work;