// src/pages/Portfolio.tsx
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import { useCallback, useEffect } from 'react';

const projects = [
    {
        title: 'Lawphoria',
        description: 'AI-powered legal assistant revolutionizing law practice',
        tag: 'AI + LEGAL TECH',
        gradient: 'from-blue-600 to-violet-600',
    },
    {
        title: 'Invoca',
        description: 'Next-gen billing system with predictive analytics',
        tag: 'FINTECH',
        gradient: 'from-violet-600 to-purple-600',
    },
    {
        title: 'PicassoClub',
        description: 'AI art generation platform for professional creators',
        tag: 'AI + CREATIVE',
        gradient: 'from-purple-600 to-pink-600',
    },
    {
        title: 'thetesseract.ai',
        description: 'All-in-one content generation platform powered by AI',
        tag: 'CONTENT GEN',
        gradient: 'from-pink-600 to-red-600',
    },
    {
        title: 'Costmaster',
        description: 'Construction calculation software with predictive costing',
        tag: 'CONSTRUCTION TECH',
        gradient: 'from-green-600 to-teal-600',
    },
    {
        title: 'BillRight',
        description: 'Billing and profit calculation inventory software',
        tag: 'INVENTORY + FINANCE',
        gradient: 'from-yellow-500 to-orange-500',
    }
];

const Portfolio = () => {
    // Scroll to top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const particlesInit = useCallback(async (engine: any) => {
        await loadSlim(engine);
    }, []);

    return (
        <div className="min-h-screen bg-black">
            <Navbar />

            {/* Background Particles */}
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: { color: { value: "#000000" } },
                    particles: {
                        color: { value: ["#0ff", "#88f", "#fff"] },
                        links: {
                            color: "#4080ff",
                            distance: 150,
                            enable: true,
                            opacity: 0.3,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 1,
                        },
                        number: {
                            density: { enable: true, area: 800 },
                            value: 80,
                        },
                        opacity: {
                            value: { min: 0.1, max: 0.5 },
                        },
                        shape: { type: "circle" },
                        size: {
                            value: { min: 1, max: 3 },
                        },
                    },
                }}
                className="absolute inset-0"
            />

            {/* Content */}
            <div className="relative z-10 pt-32 pb-20 px-4">
                <div className="max-w-7xl mx-auto">
                    <motion.div
                        className="text-center mb-16"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <img
                            src="/assets/neuralwayLogo.svg"
                            alt="Neuralway"
                            className="h-12 mx-auto mb-8"
                        />
                        <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                            Portfolio
                        </h1>
                    </motion.div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {projects.map((project, index) => (
                            <ProjectCard key={project.title} project={project} index={index} />
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

const ProjectCard = ({ project, index }: any) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, delay: index * 0.1 }}
            whileHover={{ scale: 1.02 }}
            className="relative bg-black/40 backdrop-blur-sm rounded-2xl overflow-hidden"
        >
            <div className="p-8 relative">
                <div className="space-y-4">
                    <div className="inline-block px-4 py-1 rounded-full bg-white/10 text-sm text-white/70">
                        {project.tag}
                    </div>

                    <h3 className="text-2xl font-bold text-white">{project.title}</h3>

                    <p className="text-white/70">{project.description}</p>
                </div>

                <div className={`absolute bottom-0 left-0 h-px w-full bg-gradient-to-r ${project.gradient} opacity-50`} />
            </div>
        </motion.div>
    );
};

export default Portfolio;