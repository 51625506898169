// src/pages/Privacy.tsx
import { ReactNode } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

interface PageLayoutProps {
    children: ReactNode;
    title: string;
}

export const PageLayout = ({ children, title }: PageLayoutProps) => {
    return (
        <div className="min-h-screen bg-black">
            <Navbar />
            <div className="pt-32 pb-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <img
                            src="/assets/neuralwayLogo.svg"
                            alt="Neuralway"
                            className="h-12 mx-auto mb-8"
                        />
                        <h1 className="text-4xl font-bold text-white">{title}</h1>
                    </div>
                    <div className="max-w-4xl mx-auto prose prose-invert">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const Privacy = () => {
    return (
        <PageLayout title="Privacy Policy">
            <section className="mb-12">
                <p className="text-lg mb-8">
                    This Privacy Policy explains how personal information about our customers and users is collected, used, and disclosed by Neuralway Technologies. We prioritize your privacy and are committed to protecting the confidentiality and integrity of your personal information through robust security measures and responsible data practices.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Collection and Use of Personal Information</h2>
                <p className="mb-6">
                    Neuralway Technologies collects personal information to provide and enhance our services. This includes:
                </p>
                <ul className="list-disc pl-6 mb-6 space-y-3">
                    <li>Information you provide when you sign up for services, such as your name, email address, and contact details.</li>
                    <li>Data related to your usage of our services, captured through logs and analytics tools, to improve functionality and user experience.</li>
                    <li>Information from third parties and integration partners that help us deliver and enhance our services.</li>
                </ul>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Security of Your Information</h2>
                <p>
                    We employ state-of-the-art security measures including encryption, firewalls, and secure server facilities to protect your information from unauthorized access, alteration, or destruction. We take the confidentiality of your uploaded documents seriously and implement industry-standard safeguards to protect your personal data.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Disclosure of Information</h2>
                <p className="mb-6">
                    Neuralway Technologies will not share your personal information without your consent, except in the following circumstances:
                </p>
                <ul className="list-disc pl-6 mb-6 space-y-3">
                    <li>To comply with legal obligations or governmental requests.</li>
                    <li>For internal administration and processing purposes within the context of service delivery.</li>
                    <li>With service providers acting on our behalf who have agreed to privacy restrictions similar to this policy.</li>
                </ul>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Access and Control</h2>
                <p>
                    You have the right to access, correct, or delete your personal information at any time. Please contact our support team to make such requests.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Data Retention</h2>
                <p>
                    We retain personal information for as long as necessary to provide our services and fulfill the transactions you have requested, or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our policies.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Changes to this Policy</h2>
                <p>
                    Neuralway Technologies may update this privacy policy from time to time. Any changes will be posted on this page with an updated revision date.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Your Consent</h2>
                <p>
                    By using our services, you consent to the collection, use, and sharing of your personal information as outlined in this privacy policy.
                </p>
            </section>

            <section>
                <h2 className="text-2xl font-bold mb-6">Contact Information</h2>
                <p>
                    For any inquiries or concerns regarding our service, please contact us at{' '}
                    <a href="mailto:zero@neuralway.ai" className="text-blue-400 hover:text-blue-300">
                        zero@neuralway.ai
                    </a>
                </p>
            </section>
        </PageLayout>
    );
};

export default Privacy;