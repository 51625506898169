// src/pages/Terms.tsx
import { ReactNode } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

interface PageLayoutProps {
    children: ReactNode;
    title: string;
}

export const PageLayout = ({ children, title }: PageLayoutProps) => {
    return (
        <div className="min-h-screen bg-black">
            <Navbar />
            <div className="pt-32 pb-20">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="text-center mb-16">
                        <img
                            src="/assets/neuralwayLogo.svg"
                            alt="Neuralway"
                            className="h-12 mx-auto mb-8"
                        />
                        <h1 className="text-4xl font-bold text-white">{title}</h1>
                    </div>
                    <div className="max-w-4xl mx-auto prose prose-invert">
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

const Terms = () => {
    return (
        <PageLayout title="Terms of Service">
            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Acceptance of Terms</h2>
                <p>
                    By subscribing to our service, you agree to comply with and be bound by the following Terms of Service. If you do not agree with these terms, please refrain from using our services.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Subscription Plans</h2>
                <p>
                    Our service offers various subscription plans. Details of each plan, including pricing, features, and limitations, can be found on our website. We reserve the right to modify subscription plans with prior notice.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Custom-Made Software</h2>
                <p className="mb-6">For custom-made software, payment terms may vary:</p>
                <ul className="list-disc pl-6 mb-6 space-y-3">
                    <li>One-Time Payment: A one-time payment may be required upon completion and delivery of the custom-made software.</li>
                    <li>Monthly Subscription for Maintenance: Ongoing maintenance and support may be billed on a monthly basis.</li>
                    <li>Free Maintenance: Maintenance may be provided free of charge, at our discretion, as agreed upon during the initial discussion.</li>
                </ul>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Payment and Billing</h2>
                <p>
                    Payment for the service is billed on a monthly/yearly basis. You authorize us to charge your provided payment method for the subscription fee. Any changes to billing information must be promptly updated.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Cancellation and Refunds</h2>
                <p className="mb-6">
                    We strive to deliver exceptional service and products. However, we understand that there may be times when you need to cancel your subscription.
                </p>

                <h3 className="text-xl font-semibold mb-4">Cancellation Policy</h3>
                <ul className="list-disc pl-6 mb-6 space-y-3">
                    <li>You may cancel your subscription at any time by logging into your account.</li>
                    <li>Cancellation will take effect at the end of the current billing cycle, allowing you to enjoy the service until then.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-4">Refund Policy</h3>
                <ul className="list-disc pl-6 mb-6 space-y-3">
                    <li>Refunds are generally not provided for the current or previous subscription periods as per our policy.</li>
                    <li>However, under the Consumer Protection Act, 2019, you are entitled to a full refund if the digital services provided are not as described, or fail to meet the standards promised.</li>
                    <li>If you encounter issues that qualify for a refund under the Act, please contact our customer service within 15 days of purchase to initiate the process.</li>
                </ul>

                <h3 className="text-xl font-semibold mb-4">Exceptions</h3>
                <p>
                    Special circumstances requiring deviation from this policy will be handled on a case-by-case basis, in accordance with the Consumer Protection Act, 2019 and the Information Technology (Amendment) Act, 2008.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Intellectual Property</h2>
                <p>
                    All creations during the subscription period are the intellectual property of the subscriber upon final delivery. We retain the right to display the completed work in our portfolio and marketing materials - with permission.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Confidentiality</h2>
                <p>
                    We respect the confidentiality of your projects. We will not disclose, reproduce, or share your concepts with any third parties without your explicit consent.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Termination of Service</h2>
                <p>
                    We reserve the right to terminate or suspend your subscription at our discretion for violation of these Terms of Service or any illegal or inappropriate activities.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Changes to Terms</h2>
                <p>
                    We may update these Terms of Service from time to time. Subscribers will be notified of any changes, and continued use of the service after modifications implies acceptance of the updated terms.
                </p>
            </section>

            <section className="mb-12">
                <h2 className="text-2xl font-bold mb-6">Contact Information</h2>
                <p>
                    For any inquiries or concerns regarding our service, please contact us at{' '}
                    <a href="mailto:zero@neuralway.ai" className="text-blue-400 hover:text-blue-300">
                        zero@neuralway.ai
                    </a>
                </p>
            </section>
        </PageLayout>
    );
};

export default Terms;