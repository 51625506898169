// src/components/Hero.tsx
import { motion } from 'framer-motion';
import Particles from 'react-particles';
import { loadSlim } from "tsparticles-slim";
import { useCallback } from 'react';

const Hero = () => {
    const particlesInit = useCallback(async (engine: any) => {
        await loadSlim(engine);
    }, []);

    const scrollToServices = () => {
        const element = document.getElementById('services');
        if (element) {
            const offset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div id="hero" className="relative min-h-screen flex items-center justify-center bg-black overflow-hidden">
            {/* Particle system */}
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: { color: { value: "#000000" } },
                    particles: {
                        color: { value: ["#0ff", "#88f", "#fff"] },
                        links: {
                            color: "#4080ff",
                            distance: 150,
                            enable: true,
                            opacity: 0.3,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 1,
                            direction: "none",
                            random: true,
                            straight: false,
                            outModes: "out",
                            attract: {
                                enable: true,
                                rotateX: 600,
                                rotateY: 600,
                            },
                        },
                        number: {
                            density: { enable: true, area: 800 },
                            value: 80,
                        },
                        opacity: {
                            value: { min: 0.1, max: 0.5 },
                            animation: {
                                enable: true,
                                speed: 1,
                                minimumValue: 0.1,
                            },
                        },
                        shape: { type: "circle" },
                        size: {
                            value: { min: 1, max: 3 },
                            animation: {
                                enable: true,
                                speed: 2,
                                minimumValue: 0.1,
                            },
                        },
                    },
                }}
            />

            {/* Subtle ethereal glow */}
            <div className="absolute inset-0 pointer-events-none">
                <div
                    className="absolute inset-0"
                    style={{
                        background: 'radial-gradient(circle at 50% 50%, rgba(56,182,255,0.1) 0%, transparent 70%)',
                    }}
                />
                <div
                    className="absolute inset-0"
                    style={{
                        background: 'radial-gradient(circle at 30% 50%, rgba(56,182,255,0.05) 0%, transparent 50%), radial-gradient(circle at 70% 50%, rgba(56,182,255,0.05) 0%, transparent 50%)',
                    }}
                />
            </div>

            <div className="relative z-10 text-center px-4">
                <motion.div
                    className="mb-16 relative"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, type: 'spring', stiffness: 80, damping: 15 }}
                >
                    {/* Logo halo effect */}
                    <div
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none"
                        style={{
                            width: '300px',
                            height: '300px',
                            background: 'radial-gradient(circle at center, rgba(56,182,255,0.15) 0%, rgba(56,182,255,0.05) 40%, transparent 70%)',
                            filter: 'blur(20px)',
                        }}
                    />
                    <img
                        src="/assets/neuralwayLogo.svg"
                        alt="Neuralway"
                        className="h-16 md:h-24 mx-auto relative"
                    />
                </motion.div>

                <motion.p
                    className="text-xl mb-12 text-white/90 max-w-2xl mx-auto"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3, type: 'tween', duration: 0.8 }}
                >
                    Unlock significant potential from AI.
                </motion.p>

                {/* Creative button to scroll to bottom (Contact section) */}
                <motion.button
                    className="relative px-8 py-3 rounded-lg font-medium group overflow-hidden bg-gradient-to-r from-blue-600/20 to-purple-600/20 backdrop-blur-sm border border-white/10"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={scrollToServices}
                >
                    <span className="relative z-10 text-white">Get Started</span>
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600/40 to-purple-600/40 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                </motion.button>
            </div>
        </div>
    );
};

export default Hero;
