// src/App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Portfolio from './pages/Portfolio';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <div className="bg-black min-h-screen">
                        <Navbar />
                        <Hero />
                        <Services />
                        <Work />
                        <Contact />
                        <Footer />
                    </div>
                } />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
        </Router>
    );
}

export default App;