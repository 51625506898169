import { motion, useScroll, useMotionValueEvent } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const { scrollY } = useScroll();
    const [expanded, setExpanded] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const isHome = location.pathname === '/';

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const EXPAND_THRESHOLD = 410;

    useMotionValueEvent(scrollY, 'change', (latest: number) => {
        if (latest > EXPAND_THRESHOLD) {
            setExpanded(true);
            setShowLogo(true);
        } else {
            setShowLogo(false);
            setExpanded(false);
        }
    });

    const handleNavigation = async (sectionId: string) => {
        setMenuOpen(false);
        if (isHome) {
            requestAnimationFrame(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    const offset = isMobile ? 60 : 80;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    window.scrollTo({ top: sectionId === 'hero' ? 0 : offsetPosition, behavior: 'smooth' });
                }
            });
        } else {
            await navigate('/');
            requestAnimationFrame(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    const offset = isMobile ? 60 : 80;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - offset;
                    window.scrollTo({ top: sectionId === 'hero' ? 0 : offsetPosition, behavior: 'smooth' });
                }
            });
        }
    };

    const navItems = [
        { label: 'Home', id: 'hero' },
        { label: 'Services', id: 'services' },
        { label: 'Work', id: 'work' },
        { label: 'Contact', id: 'contact' }
    ];

    const containerVariants = {
        initial: {
            width: isMobile ? '200px' : '400px',
            borderRadius: '9999px',
            backgroundColor: 'rgba(0,0,0,0.4)',
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        },
        expanded: {
            width: '90%',
            borderRadius: '1rem',
            backgroundColor: 'rgba(0,0,0,0.8)',
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        }
    };

    const logoVariants = {
        hidden: {
            opacity: 0,
            scale: 0.9,
            y: 10,
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        },
        visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: [0.4, 0.0, 0.2, 1]
            }
        }
    };

    const renderDesktopContent = () => {
        if (isMobile) return null;

        return (
            <div className="flex items-center w-full">
                {!expanded ? (
                    <div className="flex items-center justify-center w-full gap-4">
                        {navItems.map(item => (
                            <motion.button
                                key={item.id}
                                onClick={() => handleNavigation(item.id)}
                                className="text-white hover:text-white/90 transition-colors font-medium text-base"
                                initial={{ opacity: 0.6, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0.6, scale: 0.9 }}
                                transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                            >
                                {item.label}
                            </motion.button>
                        ))}
                    </div>
                ) : (
                    <div className="flex items-center justify-between w-full">
                        <motion.button
                            onClick={() => handleNavigation('hero')}
                            className="px-4 py-2 text-white hover:text-white/90 transition-colors font-medium text-base"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -20 }}
                            transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                        >
                            Home
                        </motion.button>
                        <div className="flex items-center gap-6">
                            {navItems.slice(1).map((item, index) => (
                                <motion.button
                                    key={item.id}
                                    onClick={() => handleNavigation(item.id)}
                                    className="text-white hover:text-white/90 transition-colors"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: 20 }}
                                    transition={{
                                        duration: 0.5,
                                        ease: [0.4, 0.0, 0.2, 1],
                                    }}
                                >
                                    {item.label}
                                </motion.button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderMobileContent = () => {
        if (!isMobile) return null;

        return (
            <>
                <div className="flex items-center w-full">
                    {!expanded ? (
                        <div className="flex items-center justify-center w-full">
                            <button
                                onClick={() => handleNavigation('hero')}
                                className="p-2 text-white hover:text-white/90 transition-colors"
                            >
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                    <polyline points="9 22 9 12 15 12 15 22" />
                                </svg>
                            </button>
                            <button
                                onClick={() => setMenuOpen(!menuOpen)}
                                className="p-2 text-white hover:text-white/90 transition-colors"
                            >
                                <motion.svg
                                    key={menuOpen ? 'x' : 'menu'}
                                    initial={{ rotate: 0, opacity: 1 }}
                                    animate={{
                                        rotate: menuOpen ? 90 : 0,
                                        opacity: 1
                                    }}
                                    transition={{
                                        duration: 0.3,
                                        type: 'spring',
                                        stiffness: 300,
                                        damping: 20
                                    }}
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                >
                                    {menuOpen ? (
                                        <>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </>
                                    ) : (
                                        <>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                        </>
                                    )}
                                </motion.svg>
                            </button>
                        </div>
                    ) : (
                        <div className="flex items-center justify-between w-full">
                            <button
                                onClick={() => handleNavigation('hero')}
                                className="p-2 text-white hover:text-white/90 transition-colors"
                            >
                                <svg
                                    className="w-5 h-5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                    <polyline points="9 22 9 12 15 12 15 22" />
                                </svg>
                            </button>
                            <button
                                onClick={() => setMenuOpen(!menuOpen)}
                                className="p-2 text-white hover:text-white/90 transition-colors"
                            >
                                <motion.svg
                                    key={menuOpen ? 'x' : 'menu'}
                                    initial={{ rotate: 0, opacity: 1 }}
                                    animate={{
                                        rotate: menuOpen ? 90 : 0,
                                        opacity: 1
                                    }}
                                    transition={{
                                        duration: 0.3,
                                        type: 'spring',
                                        stiffness: 300,
                                        damping: 20
                                    }}
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                >
                                    {menuOpen ? (
                                        <>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </>
                                    ) : (
                                        <>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                        </>
                                    )}
                                </motion.svg>
                            </button>
                        </div>
                    )}
                </div>

                {menuOpen && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{
                            duration: 0.5,
                            ease: [0.4, 0.0, 0.2, 1]
                        }}
                        className="absolute top-full left-0 right-0 mt-2 bg-black/90 backdrop-blur-xl border border-white/10 rounded-xl overflow-hidden shadow-lg"
                    >
                        <div className="py-2 flex flex-col">
                            {navItems.slice(1).map((item, index) => (
                                <motion.button
                                    key={item.id}
                                    onClick={() => handleNavigation(item.id)}
                                    initial={{ opacity: 0, x: -10 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -10 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: index * 0.1,
                                        ease: [0.4, 0.0, 0.2, 1]
                                    }}
                                    className="w-full px-6 py-3 text-left text-white hover:bg-white/10 transition-colors"
                                >
                                    {item.label}
                                </motion.button>
                            ))}
                        </div>
                    </motion.div>
                )}
            </>
        );
    };

    return (
        <div className="fixed top-0 left-0 w-full flex justify-center z-50 pt-4 md:pt-6 pointer-events-none">
            <motion.nav
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5, ease: [0.4, 0.0, 0.2, 1] }}
                className="pointer-events-auto flex justify-center w-full px-3 md:px-4"
            >
                <motion.div
                    variants={containerVariants}
                    animate={expanded ? 'expanded' : 'initial'}
                    className="relative backdrop-blur-md border border-white/10 shadow-lg flex items-center"
                    style={{ height: isMobile ? '50px' : '64px' }}
                >
                    {showLogo && (
                        <motion.div
                            variants={logoVariants}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            className="absolute inset-0 flex items-center justify-center pointer-events-none"
                        >
                            <img
                                src="/assets/neuralwayLogo.svg"
                                alt="Neuralway"
                                className="h-8 md:h-10 w-auto"
                            />
                        </motion.div>
                    )}

                    <div className="flex items-center justify-center w-full px-4 md:px-6 relative">
                        {renderDesktopContent()}
                        {renderMobileContent()}
                    </div>
                </motion.div>
            </motion.nav>
        </div>
    );
};

export default Navbar;